const searchBar = () => {
    const searchExpander = document.querySelector('.search-toggle'),
    mobileSearchBar = document.querySelector('.mobileSearch'),
    closeMobileSearch = document.querySelector('.mobileSearch__close');

    if(searchExpander && mobileSearchBar && closeMobileSearch) {

        searchExpander.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            mobileSearchBar.classList.toggle('mobileSearch--active')
        })
        closeMobileSearch.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            mobileSearchBar.classList.remove('mobileSearch--active')
        })
    }
}

export default searchBar