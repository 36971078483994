const menuOnScroll = () => {
  const header = document.querySelector(".site-header")
  let prev = null

  if (!header) return

  window.addEventListener("scroll", () => {
    if (prev && prev < window.scrollY) {
      prev = window.scrollY != 0 ? window.scrollY : 1
      header.classList.add("site-header--scrollDown")
    } else if (prev && prev > window.scrollY) {
      header.classList.remove("site-header--scrollDown")
      prev = window.scrollY != 0 ? window.scrollY : 1
    }

    if (prev == null) {
      prev = window.scrollY
    }
  })
}

export default menuOnScroll
