import Glide from '@glidejs/glide'
console.log('heroSlider.js')
const heroSlider = () => {

    const heroSliderContainer = document.querySelector('.heroSlider'),
    currentSlide = document.querySelector('.sliderCounter > span');
    let heroGlide

        if(heroSliderContainer && currentSlide) {

            const callbackFunc = (index) => {
                currentSlide.innerHTML = index + 1
            }

            heroGlide = new Glide(heroSliderContainer, {
                type: 'carousel',
                gap: 0,
                focusAt: 'center',
                startAt: 0,
                perView: 1
            })

            heroGlide.on('move.after', () => {
                callbackFunc(heroGlide.index)
            })
            heroGlide.mount()
    }
}

export default heroSlider