import navWalker from "./parts/nav-walker"
import burger from "./parts/burger"
import heroSlider from "./parts/heroSlider"
import createProductSlider from "./parts/productSlider"
import carousel from "./parts/carousel"
import moveToNextSection from "./parts/moveToNextSection"
import searchBar from "./parts/searchBar"
import menuOnScroll from "./parts/menuOnScroll"
import woocommerceInputNumber from "./parts/woocommerceInputNumber"
import animatedCursor from "./parts/cursorAnimation"
import animatePostPreview from "./parts/animationPostPreview"
import removeTabsOnMobile from "./parts/removeTabsOnMobile"

document.addEventListener("DOMContentLoaded", () => {
  navWalker()
  burger()
  heroSlider()
  createProductSlider()
  carousel()
  moveToNextSection()
  searchBar()
  menuOnScroll()
  woocommerceInputNumber()
  animatedCursor()
  animatePostPreview()
  removeTabsOnMobile()
})
