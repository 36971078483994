// import Glide from '@glidejs/glide'
const carousel = () => {

    const carouselTop = document.querySelector('.js-carousel-top'),
        carouselBottom = document.querySelector('.js-carousel-bottom')

    if(carouselTop && carouselBottom) {

        const carouselFirst = new Glide(carouselTop, {
            type: 'carousel',
            autoplay: 1,
            animationDuration: 10000,
            animationTimingFunc: 'linear',
            perView: 2.5,
            // peek: {before: 0, after: 300},
            gap: 32,
            breakpoints: {
                1264: {
                    perView: 5,
                    peek: 0,
                },
                1064: {
                    perView: 1.5,
                    // peek: {before: 0, after: 300},
                },
                787: {
                    perView: 1,
                    peek: 0,
                }
            }
        })

        carouselFirst.mount()

        const carouselSecond = new Glide(carouselBottom, {
            type: 'carousel',
            autoplay: 1,
            animationDuration: 11000,
            animationTimingFunc: 'linear',
            perView: 2,
            // peek: {before: 0, after: 300},
            gap: 32,
            breakpoints: {
                1264: {
                    perView: 2,
                    peek: 0,
                },
                1064: {
                    perView: 1,
                    // peek: {before: 0, after: 300},
                },
                787: {
                    perView: 1,
                    peek: 0,
                }
            }
        })

        carouselSecond.mount()
    }
}

export default carousel