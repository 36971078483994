const woocommerceInputNumber = () => {
    const buttonAdd = document.querySelector('.inputButton.add');
	const buttonRemove = document.querySelector('.inputButton.remove');
	const quantityInput = document.querySelector('.quantity > input[type="number"]');

    if(buttonAdd && buttonRemove && quantityInput) {
        buttonAdd.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            quantityInput.stepUp()
        })
        
        buttonRemove.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            quantityInput.stepDown()
        })
    }
}

export default woocommerceInputNumber