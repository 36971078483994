export default () => {
  const postContent = document.querySelectorAll(".post-content")

  if (postContent) {
    postContent.forEach((item) => {
      const header = item.querySelector(".post__noPadding"),
        headerHeight = getComputedStyle(header).height,
        vail = item.querySelector(".vail")

      vail.style.transform = `translateY(calc(100% - ${headerHeight} - 24px))`

      item.addEventListener("mouseover", () => {
        const content = item.querySelector(".p"),
          contentHeight = getComputedStyle(content).height,
          hidden = item.querySelector(".hidden")

        hidden.style.maxHeight = contentHeight
        vail.style.transform = "translateY(0)"
      })

      item.addEventListener("mouseleave", () => {
        const hidden = item.querySelector(".hidden"),
          headerHeight = getComputedStyle(header).height

        hidden.style.maxHeight = 0 + "px"
        vail.style.transform = `translateY(calc(100% - ${headerHeight} - 24px))`
      })

      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const header = entry.target.querySelector(".post__noPadding"),
            headerHeight = getComputedStyle(header).height,
            vail = entry.target.querySelector(".vail")

          vail.style.transform = `translateY(calc(100% - ${headerHeight} - 24px))`
        })
      })

      postContent.forEach((value) => {
        resizeObserver.observe(value)
      })
    })
  }
}
