import Glide from "@glidejs/glide";
console.log("productSlider.js");
const createProductSlider = () => {
  const sliders = [...document.querySelectorAll(".product-glide")]

  sliders.map((productSliderContainer) => {
    if (productSliderContainer) {
      let productGlide
      const startingPeek = window.innerWidth * 0.2

      productGlide = new Glide(productSliderContainer, {
        type: "carousel",
        perView: 3.8,
        gap: 16,
        rewind: false,
        bound: true,
        peek: { before: 0, after: startingPeek },
        focusAt: "center",
        breakpoints: {
          1200: {
            perView: 3.2,
            gap: 8,
            peek: { before: 0, after: 50 },
          },
          992: {
            perView: 2.4,
            gap: 8,
            peek: { before: 0, after: 50 },
          },
          767: {
            perView: 1.8,
            gap: 8,
            peek: { before: 0, after: 50 },
          },
          560: {
            perView: 1.1,
            gap: 4,
            peek: { before: 0, after: 50 },
          },
        },
      })

      productGlide.mount()

      // set up slider p element after mount, when all clone elements are added

      const glideSlides = [
          ...productSliderContainer.querySelectorAll(`.glide__slide`),
        ],
        paddingBottom = 16

      setTimeout(() => {
        glideSlides.map((slide) => {
          const paragraph = slide.querySelector(".p"),
            paragraphHeight = paragraph.offsetHeight + paddingBottom

          paragraph.classList.add("hidden")

          slide.addEventListener("mouseenter", () => {
            if (window.innerWidth > 767) {
              paragraph.style.height = paragraphHeight + "px"
            }
          })

          slide.addEventListener("mouseleave", () => {
            if (window.innerWidth > 767) {
              paragraph.style.height = 1 + "px"
            }
          })
        })
      }, 300)

      productGlide.on("resize", () => {
        if (window.innerWidth > 788) {
          const glideSlide = productSliderContainer.querySelector(
            ".glide__slide--active"
          )
          productGlide.settings.perView = 2
          productGlide.settings.peek.after = glideSlide.offsetWidth / 2
          productGlide.update()
        }
      })
    }
  })
}

export default createProductSlider
