const burger = () => {
	const menuBtn = document.querySelector(".menu-btn"),
	menuToggle = document.querySelector('button.menu-toggle'),
		modal = document.querySelector('.menu-modal'),
		closeModal = document.querySelector('.menu-modal__close'),
		html = document.querySelector('html'),
		mainContainer = document.querySelector('.main-container'),
		siteHeader = document.querySelector('.site-header'),
		animatedSection = document.querySelectorAll('.mobile-carousel .footerSidebar__leftColumn');

	
	if(menuBtn && modal && menuToggle) {
		menuToggle.addEventListener("click", () => {

			menuBtn.classList.add("open")
			menuToggle.setAttribute('aria-expanded', true)
			modal.classList.add('menu-modal--active')
			mainContainer.classList.add('main-container--hidden')
			siteHeader.classList.remove('site-header--scrollDown')
			animatedSection.forEach(item => {
				item.classList.add('animated')
			})
			html.style.overflow= 'hidden'
			setTimeout(() => {
			mainContainer.style.display = 'none'
			}, 200)
		});
	}
	
	if(closeModal && modal && menuToggle) {
		closeModal.addEventListener("click", () => {
			
			menuBtn.classList.remove("open")
			menuToggle.setAttribute('aria-expanded', false)
			modal.classList.remove('menu-modal--active')
			mainContainer.style.display = 'grid'
			setTimeout(() => {
				mainContainer.classList.remove('main-container--hidden')
			}, 50)
			animatedSection.forEach(item => {
				item.classList.remove('animated')
			})
			html.style.overflow= 'auto'
		});
	}
}

export default burger