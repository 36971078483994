const navWalker = () => {
    
    const dropdown = [...document.querySelectorAll('.dropdown')],
    gansaWalker = [...document.querySelectorAll('.gansa_walker a')];
    const url = window.location.href;

    if(gansaWalker) {
    gansaWalker.map( item => {
        if(item.href === url) {
            item.closest('li').classList.add('active')
        }
    })
}

    const removeClickListener = () => {
        document.removeEventListener('click', hideOnClickOutside)
    }

    const hideOnClickOutside = (e) => {
        if(e.target && !e.target.closest('.dropdown')){
            document.querySelector('.dropdown-menu').classList.remove('dropdown-menu--active')
            removeClickListener()
        }
    }

    if(dropdown) {
        dropdown.forEach( item => {
            item.addEventListener('click', (e) => {
                const dropdownMenu = item.querySelector('.dropdown-menu')
                dropdownMenu.classList.toggle('dropdown-menu--active')
                    
                const paddingTop = parseInt(window.getComputedStyle(dropdownMenu).paddingTop),
                paddingBottom = parseInt(window.getComputedStyle(dropdownMenu).paddingBottom),
                startValue = paddingTop + paddingBottom

                const active = item.querySelector('.dropdown-menu--active');
                if(active) {
                    const maxHeight = [...active.childNodes].reduce( (prev, current) => {
                        const currentHeight = current.offsetHeight
                        return prev + currentHeight
                    }, startValue)
    
                    active.style.height = maxHeight + 'px'
                } else {
                    dropdownMenu.style.height = 0;
                }
                

                document.addEventListener('click', hideOnClickOutside)
            }) 
        })
    }
}

export default navWalker