export default () => {
  const tabsHeader = document.querySelector(".tabs.wc-tabs"),
    dashed = document.querySelector(".woocommerce-tabs > .dashed"),
    tabsContent = document.querySelectorAll(".woocommerce-Tabs-panel")

  if (tabsHeader && dashed && tabsContent) {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 787) {
        tabsHeader.style.display = "none"
        dashed.style.display = "none"
        tabsContent.forEach((tab) => {
          tab.style.display = "block"
        })
      } else {
        tabsHeader.style.display = "flex"
        dashed.style.display = "block"
        tabsContent.forEach((tab) => {
          const activeTabLabel = tabsHeader
            .querySelector("li.active")
            .getAttribute("aria-controls")

          if (tab.id === activeTabLabel) {
            tab.style.display = "block"
          } else {
            tab.style.display = "none"
          }
        })
      }
    })

    window.addEventListener("load", () => {
      if (window.innerWidth < 787) {
        tabsHeader.style.display = "none"
        dashed.style.display = "none"
        tabsContent.forEach((tab) => {
          tab.style.display = "block"
        })
      } else {
        tabsHeader.style.display = "flex"
        dashed.style.display = "block"
        tabsContent.forEach((tab) => {
          const activeTabLabel = tabsHeader
            .querySelector("li.active")
            .getAttribute("aria-controls")

          if (tab.id === activeTabLabel) {
            tab.style.display = "block"
          } else {
            tab.style.display = "none"
          }
        })
      }
    })
  }
}
