const moveToNextSection = () => {
    const heroSection = document.querySelector('.hero'),
    goFurther = document.querySelector('.bootomLeftStatic button');

    if(goFurther && heroSection) {
        const nextSection = heroSection.nextElementSibling

        goFurther.addEventListener('click', () => {
            nextSection.scrollIntoView({behavior: "smooth", block: "start"})
        })
    }
}

export default moveToNextSection