const animatedCursor = () => {
  const cursor = document.querySelector(".followCursor")

  window.addEventListener("mousemove", (e) => {
    setTimeout(() => {
      // YY

      cursor.style.top = e.clientY + "px"

      if (e.clientY < 24) {
        cursor.style.top = 24 + "px"
      }

      if (e.clientY > window.innerHeight - 45) {
        cursor.style.top = window.innerHeight - 40 + "px"
      }

      // XX
      if (e.pageX > 24 && e.pageX < window.innerWidth - 52) {
        cursor.style.left = e.pageX + "px"
      } else {
        if (e.pageX < 24) {
          cursor.style.left = 24 + "px"
        }
        if (e.pageX > window.innerWidth - 52) {
          cursor.style.right = -24 + "px"
        }
      }
    }, 50)
  })

  // ------------------
  const followCursorZoom = document.querySelector(
    ".followCursor__img-container--zoom"
  )
  const woocomerceActiveImg = document.querySelector(
    ".woocommerce-product-gallery"
  )

  if (followCursorZoom && woocomerceActiveImg) {
    woocomerceActiveImg.addEventListener("mouseover", () => {
      followCursorZoom.style.display = "flex"
      setTimeout(() => {
        followCursorZoom.classList.add("active")
      }, 100)
    })

    woocomerceActiveImg.addEventListener("mouseleave", () => {
      followCursorZoom.classList.remove("active")
      setTimeout(() => {
        followCursorZoom.style.display = "none"
      }, 400)
    })
  }

  // --------------

  const followCursorQuestion = document.querySelector(
    ".followCursor__img-container--question"
  )
  const buttonAskAbout = document.querySelector(".caldera-forms-modal")

  if (followCursorQuestion && buttonAskAbout) {
    buttonAskAbout.addEventListener("mouseover", () => {
      followCursorQuestion.style.display = "flex"
      setTimeout(() => {
        followCursorQuestion.classList.add("active")
      }, 100)
    })

    buttonAskAbout.addEventListener("mouseleave", () => {
      followCursorQuestion.classList.remove("active")
      setTimeout(() => {
        followCursorQuestion.style.display = "none"
      }, 400)
    })
  }
  //-----
  const followCursorHand = document.querySelector(
    ".followCursor__img-container--hand"
  )
  const allLinks = document.querySelectorAll("a:not(.remove), button, .menu-btn__burger")

  allLinks.forEach((link) => {
    link.addEventListener("mouseover", () => {
      if (link.parentElement.classList.contains("flex-active-slide")) return
      followCursorHand.style.display = "flex"
      setTimeout(() => {
        followCursorHand.classList.add("active")
      }, 100)
    })

    link.addEventListener("mouseleave", () => {
      if (link.parentElement.classList.contains("flex-active-slide")) return
      followCursorHand.classList.remove("active")
      followCursorHand.style.display = "none"
    })
  })

  //----------
  const followCursorAdd = document.querySelector(
    ".followCursor__img-container--add"
  )
  const buttonAddToCart = document.querySelector('button[name="add-to-cart"]')

  if (followCursorAdd && buttonAddToCart) {
    buttonAddToCart.addEventListener("mouseover", () => {
      cursor.classList.add("followCursor--green")
      followCursorAdd.style.display = "flex"
      setTimeout(() => {
        followCursorAdd.classList.add("active")
      }, 100)
    })

    buttonAddToCart.addEventListener("mouseleave", () => {
      followCursorAdd.classList.remove("active")
      setTimeout(() => {
        followCursorAdd.style.display = "none"
        cursor.classList.remove("followCursor--green")
      }, 200)
    })
  }

  //-----------------------
  const followCursorRemove = document.querySelector(
      ".followCursor__img-container--remove"
    ),
    remove = document.querySelector("a.remove")

  if (followCursorRemove && remove) {
    remove.addEventListener("mouseover", () => {
      cursor.classList.add("followCursor--red")
      followCursorRemove.style.display = "flex"
      setTimeout(() => {
        followCursorRemove.classList.add("active")
      }, 100)
    })

    remove.addEventListener("mouseleave", () => {
      followCursorRemove.classList.remove("active")
      setTimeout(() => {
        followCursorRemove.style.display = "none"
        cursor.classList.remove("followCursor--red")
      }, 200)
    })
  }
}

export default animatedCursor
